<template>
  <div class="">
    <el-row>
      <el-col :span="18" :xs="24" :sm="24" :md="21" class="qr-code-generator-container">
        <div class="header-container">
          <div class="left-item">
            <img :src="require('@/assets/img/qrcode/FiberAPI-logo-grey.svg')" class="fiber-logo">
          </div>
          <div class="back-button" @click="$router.go(-1)">
            <i class="fas fa-chevron-left back-icon" />Back
          </div>
        </div>
        <div class="qr-code-generator-success space-bottom-2">
          <el-row>
            <el-col :span="24" :xs="24">
              <h3 class="section-title">Congratulations! Your FPS QR Code is Ready!</h3>
              <p class="description">*This is a free FPS QR code generator, the QR code generated is valid for 7 days (From the time it is generated).</p>
              <div class="text-center">
                <img v-if="qrImage" :src="qrImage" class="qr-code-img"><br>
                <img :src="require('@/assets/img/qrcode/fps-fiber.png')" class="poweredby-image">
              </div>
              <div class="button-container">
                <el-button type="primary" class="save-button" :disabled="qrImage === ''" @click="downloadQr">Save</el-button>
              </div>
              <!-- <div class="fps-service-table-container space-top-3">
                <h3 class="section-title">Need More FPS Value-added Services?</h3>
                <el-table :data="tableData" :border="false" class="fps-service-table" cell-class-name="table-cell" header-row-class-name="table-header-row">
                  <el-table-column>
                    <el-table-column prop="function" label="Features" width="300" />
                  </el-table-column>
                  <el-table-column label="FiberConnect" align="center">>
                    <el-table-column prop="free_qr_code" label="FPS QR Code Generator (Free)" align="center" min-width="150">
                      <template slot-scope="scope">
                        <i v-if="scope.row.free_qr_code" class="el-icon-success" />
                      </template>
                    </el-table-column>
                    <el-table-column prop="simple_fps_billing" label="FPS Invoicing (Lite)" align="center" min-width="150">
                      <template slot-scope="scope">
                        <i v-if="scope.row.simple_fps_billing" class="el-icon-success" />
                      </template>
                    </el-table-column>
                    <el-table-column prop="pro_fps_billing" label="FPS Invoicing (Pro)" align="center" min-width="150">
                      <template slot-scope="scope">
                        <i v-if="scope.row.pro_fps_billing" class="el-icon-success" />
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="API Service" align="center">
                    <el-table-column prop="fps_api" label="FPS API" align="center" min-width="150">
                      <template slot-scope="scope">
                        <i v-if="scope.row.fps_api" class="el-icon-success" />
                      </template>
                    </el-table-column>
                  </el-table-column>
                </el-table>
              </div> -->

              <!-- <div class="space-top-2">
                <div class="fps-service-promotion-container space-2 px-2">
                  <h2 class="text-center mb-6">Want to Do More with FiberConnect?</h2>
                  <div class="w-md-80 mx-md-auto mb-6">
                    <div class="row justify-content-lg-center align-items-md-center">
                      <div class="col-12 col-lg-5 mb-7 mb-md-0 md-order-2">
                        <div class="position-relative w-80 w-lg-100 mx-auto">
                          <img :src="require('@/assets/img/qrcode/fiberconnect-screens.png')" class="img-fluid">
                        </div>
                      </div>
                      <div class="col-12 col-lg-7 md-order-1 px-4 px-md-0">
                        <div class="media mb-4">
                          <span class="icon icon-xs icon-soft-success icon-circle mr-3">
                            <i class="fas fa-check" />
                          </span>
                          <div class="media-body text-dark">
                            Create payment requests in seconds
                          </div>
                        </div>
                        <div class="media mb-4">
                          <span class="icon icon-xs icon-soft-success icon-circle mr-3">
                            <i class="fas fa-check" />
                          </span>
                          <div class="media-body text-dark">
                            Share payment links through any medium
                          </div>
                        </div>
                        <div class="media mb-4">
                          <span class="icon icon-xs icon-soft-success icon-circle mr-3">
                            <i class="fas fa-check" />
                          </span>
                          <div class="media-body text-dark">
                            Accept direct payments through QR codes
                          </div>
                        </div>
                        <div class="media mb-4">
                          <span class="icon icon-xs icon-soft-success icon-circle mr-3">
                            <i class="fas fa-check" />
                          </span>
                          <div class="media-body text-dark">
                            Integrate with your online store to collect payment instantly
                          </div>
                        </div>
                        <div class="media mb-4">
                          <span class="icon icon-xs icon-soft-success icon-circle mr-3">
                            <i class="fas fa-check" />
                          </span>
                          <div class="media-body text-dark">
                            Keep track of payments you receive
                          </div>
                        </div>
                        <a id="" class="media" href="https://fiberapi.com" target="_blank" style="color:#29b8ac">
                          <span class="icon icon-xs icon-soft-success icon-circle mr-3">
                            <i class="fas fa-ellipsis-h" />
                          </span>
                          <div class="media-body">
                            and find out more here!
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="button-container">
                    <a href="https://app.fiberapi.com/" target="_blank">
                      <el-button type="primary" @click="learnMore">Start Free Trial</el-button>
                    </a>
                    <a href="https://fiberapi.com/zh/contact/" target="_blank">
                      <el-button type="secondary" @click="contactUs">Contact Us</el-button>
                    </a>
                  </div>
                </div>
              </div> -->
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <div class="fps-service-promotion-container">
      <div class="container space-2">
        <h3 class="section-title">Want to Do More with FiberConnect?</h3>
        <p class="description text-center">Continue to explore our popular features, and start growing your business with FiberConnect today!</p>

        <div class="row mt-8">
          <div class="col-md-4 mb-9 mb-md-0">
            <div class="text-center">
              <figure class="max-w-10rem mx-auto mb-2">
                <img class="img-fluid" src="@/assets/img/qrcode/product-qrcode.png">
              </figure>
              <h4>Free FPS QR Code Generator</h4>
              <p>Generate FPS QR Code quickly and share for instant payment collection.</p>
              <div class="button-container mt-1">
                <a href="https://www.openapihub.com/fps-qrcode-generator/" target="_blank">
                  <el-button type="primary" @click="generateFreeQRCode">Generate for FREE</el-button>
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-9 mb-md-0">
            <div class="text-center">
              <figure class="max-w-10rem mx-auto mb-2">
                <img class="img-fluid" src="@/assets/img/qrcode/product-api.png">
              </figure>
              <h4>FiberConnect FPS API</h4>
              <p>Browse FiberConnect API Developer Portal and try out our FPS API.</p>
              <div class="button-container mt-1">
                <a href="https://hub.openapihub.com/v/fiberconnect-bvtxh/home" target="_blank">
                  <el-button type="primary" @click="testApi">Apply for Testing</el-button>
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-0 mb-md-0">
            <div class="text-center">
              <figure class="max-w-10rem mx-auto mb-2">
                <img class="img-fluid" src="@/assets/img/qrcode/product-sales-portal.png">
              </figure>
              <h4>FiberConnect Sales Portal</h4>
              <p>Register FiberConnect and explore all the tools to grow your business.</p>
              <div class="button-container mt-1">
                <a href="https://app.fiberapi.com/" target="_blank">
                  <el-button type="primary" @click="portalFreeTier">Start Free Tier</el-button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const QRCode = require('qrcode')

export default {
  name: 'GenerateQRCode',
  props: {
    qrCode: {
      type: String,
      default: ''
    },
    billNo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      qrImage: '',
      tableData: [
        {
          function: 'Generate FPS QR Code',
          free_qr_code: true,
          simple_fps_billing: true,
          pro_fps_billing: true,
          fps_api: true
        },
        {
          function: 'Generate invoice with FPS QR Code',
          free_qr_code: false,
          simple_fps_billing: false,
          pro_fps_billing: true,
          fps_api: false
        },
        {
          function: 'One-click email invoicing',
          free_qr_code: false,
          simple_fps_billing: false,
          pro_fps_billing: true,
          fps_api: false
        },
        {
          function: 'Auto payment confirmation & receipt',
          free_qr_code: false,
          simple_fps_billing: false,
          pro_fps_billing: true,
          fps_api: false
        },
        {
          function: 'Real-time payment status update',
          free_qr_code: false,
          simple_fps_billing: true,
          pro_fps_billing: true,
          fps_api: true
        },
        {
          function: 'Auto payment reminder',
          free_qr_code: false,
          simple_fps_billing: false,
          pro_fps_billing: true,
          fps_api: false
        },
        {
          function: 'Dashboard for monitoring sales trend',
          free_qr_code: false,
          simple_fps_billing: true,
          pro_fps_billing: true,
          fps_api: true
        },
        {
          function: 'API for integration',
          free_qr_code: false,
          simple_fps_billing: false,
          pro_fps_billing: false,
          fps_api: true
        }
      ]
    }
  },
  mounted() {
    if (this.qrCode) {
      QRCode.toDataURL(this.qrCode, {}, (err, url) => {
        if (err) throw err

        this.qrImage = url
      })
    }
  },
  methods: {
    getImageUrl(name) {
      return require('@/assets/img/qrcode/' + name)
    },
    downloadQr() {
      this.$gtag.event('/save')
      const a = document.createElement('a')
      a.href = this.qrImage
      this.billNo === '' ? a.download = 'FiberAPI-fps-qrcode.png' : a.download = `FiberAPI-fps-${this.billNo}.png`
      // a.download = 'fiber-qrcode.png'
      a.click()
    },
    // learnMore() {
    //   this.$gtag.event('/learn-more')
    // },
    // contactUs() {
    //   this.$gtag.event('/contact-us')
    // },
    generateFreeQRCode() {
      this.$gtag.event('/generate-free-qrcode')
    },
    testApi() {
      this.$gtag.event('/test-api')
    },
    portalFreeTier() {
      this.$gtag.event('/portal-free-tier')
    }
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'Free FPS QR Code Generator | %s',
      meta: [
        { name: 'description', content: 'FiberConnect Free FPS QR Code Generator enables you to create FPS payment QR Codes and accept payments instantly with ease.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'Free FPS QR Code Generator | OpenAPIHub' },
        { property: 'og:description', content: 'FiberConnect Free FPS QR Code Generator enables you to create FPS payment QR Codes and accept payments instantly with ease.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/fps-qrcode-generator.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/fps-qrcode-generator-result' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.qr-code-generator-container {
  padding: 15px;
  padding-bottom: 0px;
  color: #404040;
  float: none;
  margin-left: auto;
  margin-right: auto;
}
.header-container {
  padding-right: 10px;
  padding-left: 10px;
  // height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .left-item {
    margin-right: auto;
    font-weight: 700;
    font-size: 1.5rem;
    color: #1d4b54;
    .fiber-logo {
      width: 144px;
      height: auto;
    }
  }
  .back-button {
    margin-top: 52px;
    margin-bottom: 32px;
    margin-left: 5px;
    margin-right: auto;
    color: #29b8ac;
    cursor: pointer;
    .back-icon {
      margin-right: 8px;
    }
  }
  .center-item {
    text-align: center;
    font-size: 40px;
    line-height: 55px;
    font-weight: 700;
    color: #092825;
    .brand-name {
      color: #29b8ac;
    }
  }
}
.section-title {
  color: #092825;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  margin-bottom: 16px;
}
.qr-code-generator-success {
  margin: 0px auto;
  padding-right: 10px;
  padding-left: 10px;
  max-width: 1200px;
  // text-align: center;
  > div > div {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .description {
    text-align: center;
    max-width: 540px;
    margin: auto;
  }
  .qr-code-img {
    width: 300px;
    height: 300px;
    margin-top: 32px;
  }
  .poweredby-image {
    height: 56px;
    width: auto;
  }
}
.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 32px;
  > button, a button {
    font-size: 16px;
    font-weight: 700;
    height: 40px;
    min-width: 120px;
    border-color: #29B8AC;
    background-color: #29B8AC;
    color: #FFFFFF;
  }
  a + a {
    margin-left: 24px;
  }
  > a:first-child button {
    border-color: #29B8AC;
    background-color: #29B8AC;
    color: #FFFFFF;
  }
  > a:not(:first-child) button {
    border-color: #29B8AC;
    background-color: #FFFFFF;
    color: #29B8AC;
  }
  .save-button {
    min-width: auto;
  }
}
.el-button--primary:disabled {
  border-color: #D0EDEA;
  background-color: #D0EDEA;
}
.fps-service-promotion-container {
  background-color: #F6FFFF;
}
@media screen and (max-width:768px) {
  .header-container {
    padding: 0px;
    .back-button {
      margin-top: 39px;
    }
    .center-item {
      font-size: 28px;
      line-height: 38px;
    }
  }
  .section-title {
    font-size: 24px;
    line-height: 33px;
  }
  // .qr-code-generator-success .description {
  //   text-align: justify;
  // }
}
</style>
